<template>
  <NavigationGrid :nav-items="navItemList" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, NavigationGridItem } from "@tager/admin-ui";
import { useI18n, useResource } from "@tager/admin-services";
import { getPageCount } from "@tager/admin-pages";
import { getBlogPostCount } from "@tager/admin-blog";
import { getBannerCount } from "@tager/admin-banners";
import { getFilesCount } from "@tager/admin-files";

import { getLinks } from "@/constants/links";
import { getVideosCount } from "@/modules/videos";

export default defineComponent({
  name: "Home",
  components: { NavigationGrid },
  setup() {
    const { t } = useI18n();

    const [
      fetchVideosCount,
      { data: videosCountData, status: videosCountDataStatus },
    ] = useResource({
      fetchResource: () => getVideosCount(),
      initialValue: null,
      resourceName: "Videos Count",
    });

    const [
      fetchPageCount,
      { data: pageCountData, status: pageCountDataStatus },
    ] = useResource({
      fetchResource: () => getPageCount(),
      initialValue: null,
      resourceName: "Page Count",
    });

    const [
      fetchFileCount,
      { data: filesCountData, status: filesCountDataStatus },
    ] = useResource({
      fetchResource: getFilesCount,
      initialValue: null,
      resourceName: "Files Count",
    });

    const [
      fetchPostCount,
      { data: postCountData, status: postCountDataStatus },
    ] = useResource({
      fetchResource: getBlogPostCount,
      initialValue: null,
      resourceName: "Post Count",
    });

    const [
      fetchBannersCount,
      { data: bannerCountData, status: bannerCountDataStatus },
    ] = useResource({
      fetchResource: getBannerCount,
      initialValue: null,
      resourceName: "Banners Count",
    });

    onMounted(() => {
      fetchBannersCount();
      fetchPageCount();
      fetchPostCount();
      fetchFileCount();
      fetchVideosCount();
    });

    const links = computed(() => getLinks());

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.PAGE_LIST.text,
          url: links.value.PAGE_LIST.url,
          total: {
            value: pageCountData.value?.count ?? 0,
            status: pageCountDataStatus.value,
          },
        },
        {
          name: links.value.BLOG_POST_LIST.text,
          url: links.value.BLOG_POST_LIST.url,
          total: {
            value: postCountData.value?.count ?? 0,
            status: postCountDataStatus.value,
          },
        },
        {
          name: links.value.BANNERS_LIST.text,
          url: links.value.BANNERS_LIST.url,
          total: {
            value: bannerCountData.value?.count ?? 0,
            status: bannerCountDataStatus.value,
          },
        },
        {
          name: links.value.VIDEOS_LIST.text,
          url: links.value.VIDEOS_LIST.url,
          total: {
            value: videosCountData.value?.count ?? 0,
            status: videosCountDataStatus.value,
          },
        },
        {
          name: links.value.FILES_LIST.text,
          url: links.value.FILES_LIST.url,
          total: {
            value: filesCountData.value?.count ?? 0,
            status: filesCountDataStatus.value,
          },
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
</script>

<style scoped></style>
