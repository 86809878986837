import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: 
      _ctx.isCreation ? 'Добавить видеообзор' : 'Редактировать видеообзор ID ' + _ctx.id
    ,
    "is-content-loading": _ctx.isLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.title,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.title) = $event)),
          name: "title",
          error: _ctx.errors.title,
          label: "Заголовок"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.youtubeUrl,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.youtubeUrl) = $event)),
          name: "youtubeUrl",
          error: _ctx.errors.youtubeUrl,
          label: "Youtube URL"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.values.image,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.image) = $event)),
          error: _ctx.errors.image,
          label: "Картинка",
          name: "icon",
          "file-type": "image",
          scenario: "video-image"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldMultiSelect, {
          "selected-options": _ctx.values.dealers,
          "onUpdate:selectedOptions": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.dealers) = $event)),
          label: "Дилеры",
          name: "dealers",
          options: _ctx.dealersOptions
        }, null, 8, ["selected-options", "options"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}