import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import VideosForm from "@/modules/videos/VideosForm";
import VideosList from "@/modules/videos/VideosList";

export const getVideosListUrl = () => {
  return "/videos";
};

export const getVideosCreateUrl = () => {
  return "/videos/create";
};
export const getVideosUpdateUrl = (id: number) => {
  return "/videos/" + id;
};

export const VIDEOS_LIST_ROUTE: RouteRecordRaw = {
  path: "/videos",
  component: VideosList,
  name: "Videos List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().VIDEOS_LIST],
  },
};

export const VIDEOS_FORM_ROUTE: RouteRecordRaw = {
  path: "/videos/:id",
  component: VideosForm,
  name: "Videos Form",
  meta: {
    getBreadcrumbs: () => [
      getLinks().HOME,
      getLinks().VIDEOS_LIST,
      { url: "", text: "Видеообзор" },
    ],
  },
};
