import { request, ResponseBody } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import {
  VideoCreateOrUpdatePayload,
  VideoFullInterface,
  VideoInterface,
} from "@/modules/videos/typings";

export interface CountData {
  count: number;
}

export function getVideosCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/videos/count" });
}

export function getVideos(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<VideoInterface>>> {
  return request.get({ path: "/admin/videos", params });
}

export function getVideo(
  id: string | number
): Promise<ResponseBody<VideoFullInterface>> {
  return request.get({ path: `/admin/videos/${id}` });
}

export function createVideo(
  payload: VideoCreateOrUpdatePayload
): Promise<ResponseBody<VideoFullInterface>> {
  return request.post({
    path: `/admin/videos`,
    body: payload,
  });
}

export function updateVideo(
  id: number,
  payload: VideoCreateOrUpdatePayload
): Promise<ResponseBody<VideoFullInterface>> {
  return request.put({
    path: `/admin/videos/${id}`,
    body: payload,
  });
}

export function deleteVideo(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/videos/${id}` });
}

export function getVideosDealers(): Promise<
  ResponseBody<Array<OptionType<number>>>
> {
  return request.get({ path: "/admin/videos/dealers" });
}
