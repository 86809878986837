import { ColumnDefinition } from "@tager/admin-ui";

import { VideoInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<VideoInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "title",
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
  },
  {
    id: 4,
    name: "Youtube",
    field: "youtubeUrl",
    type: "link",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
