<template>
  <Page
    :title="
      isCreation ? 'Добавить видеообзор' : 'Редактировать видеообзор ID ' + id
    "
    :is-content-loading="isLoading"
  >
    <form novalidate @submit.prevent>
      <FormField
        v-model:value="values.title"
        name="title"
        :error="errors.title"
        label="Заголовок"
      />
      <FormField
        v-model:value="values.youtubeUrl"
        name="youtubeUrl"
        :error="errors.youtubeUrl"
        label="Youtube URL"
      />
      <FormFieldFileInput
        v-model:value="values.image"
        :error="errors.image"
        label="Картинка"
        name="icon"
        file-type="image"
        scenario="video-image"
      />
      <FormFieldMultiSelect
        v-model:selected-options="values.dealers"
        label="Дилеры"
        name="dealers"
        :options="dealersOptions"
      />
    </form>
    <template #footer>
      <FormFooter
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        :is-creation="isCreation"
        :can-create-another="isCreation"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldFileInput,
  FormFieldMultiSelect,
} from "@tager/admin-ui";

import { getVideosUpdateUrl, getVideosListUrl } from "../routes";
import {
  createVideo,
  getVideo,
  getVideosDealers,
  updateVideo,
} from "../requests";

import {
  FormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
  getVideoFormValue,
} from "./VideosForm.helpers";

export default defineComponent({
  name: "VideosForm",
  components: {
    Page,
    FormField,
    FormFooter,
    FormFieldFileInput,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getVideo(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Video",
    });

    onMounted(() => {
      fetchModel();
    });
    watch(id, fetchModel);

    const [
      fetchVideosDealers,
      { data: videosDealers, loading: isVideosDealersLoading },
    ] = useResource({
      fetchResource: () => getVideosDealers(),
      initialValue: null,
      resourceName: "Videos Dealers",
    });

    const dealersOptions = computed(
      () =>
        videosDealers.value?.map((item) => ({
          value: item.value,
          label: item.label,
        })) || []
    );

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getVideoFormValue(model.value, dealersOptions.value)
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getVideoFormValue(model.value, dealersOptions.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    watch([model, dealersOptions], () => {
      updateFormValues();
    });

    onMounted(() => {
      fetchVideosDealers();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise =
        isCreation.value || !id.value
          ? createVideo(createOrUpdatePayload)
          : updateVideo(+id.value, createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getVideosUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getVideosListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getVideoFormValue(null, dealersOptions.value);
          }

          toast.show({
            variant: "success",
            title: "Видеобзоры",
            body: isCreation.value
              ? "Видеобзор успешно создан"
              : "Видеобзор успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Видеобзоры",
            body: isCreation.value
              ? "Ошибка добавления видеобзора"
              : "Ошибка изменения видеобзора",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () => isModelLoading.value || isVideosDealersLoading.value
    );

    return {
      id,
      isLoading,
      values,
      errors,
      model,
      isCreation,
      submitForm,
      backButtonUrl: getVideosListUrl(),
      isSubmitting,

      dealersOptions,
    };
  },
});
</script>

<style lang="scss">
.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>
