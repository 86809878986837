import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import {
  VideoCreateOrUpdatePayload,
  VideoFullInterface,
} from "@/modules/videos/typings";

export type FormValues = {
  title: string;
  youtubeUrl: string;
  image: Nullable<SingleFileInputValueType>;
  dealers: Array<OptionType<number>>;
};

export function getVideoFormValue(
  model: Nullable<VideoFullInterface>,
  dealersOptions: Array<OptionType<number>>
): FormValues {
  if (!model) {
    return {
      title: "",
      youtubeUrl: "",
      image: null,
      dealers: [],
    };
  }

  return {
    title: model.title || "",
    youtubeUrl: model.youtubeUrl || "",
    image: model.image
      ? {
          id: createId(),
          file: model.image,
        }
      : null,
    dealers: dealersOptions.filter((item) =>
      model.dealers.includes(item.value)
    ),
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues
): VideoCreateOrUpdatePayload {
  return {
    title: values.title,
    youtubeUrl: values.youtubeUrl,
    image: values.image?.file.id || null,
    dealers: values.dealers?.map((item) => item.value),
  };
}
