<template>
  <Page
    title="Видеобзоры"
    :header-buttons="[
      {
        text: 'Создать видеообзор',
        href: getVideosCreateUrl(),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(actions)="{ row }">
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy(row.id)"
          :href="getVideosUpdateUrl(row.id)"
        >
          <EditIcon />
        </BaseButton>

        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id) || row.canDelete === false"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
  OptionType,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResource, useResourceDelete } from "@tager/admin-services";

import {
  getVideosCreateUrl,
  getVideosDealers,
  getVideosUpdateUrl,
} from "@/modules/videos";

import { deleteVideo, getVideos } from "../requests";
import { VideoInterface } from "../typings";

import { COLUMN_DEFS } from "./VideosList.helpers";

export default defineComponent({
  name: "VideosList",
  components: {
    Page,
    DataTable,
    BaseButton,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<VideoInterface>({
      fetchEntityList: (params) =>
        getVideos({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список видео",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteVideo,
      resourceName: "Видео",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,

      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      isBusy,
      handleResourceDelete,
      getVideosUpdateUrl,
      getVideosCreateUrl,
    };
  },
});
</script>
